<script setup lang="ts">
import Tools from '../../pages/agents/tabs/config/Tools.vue';
import AppInput from '../app/AppInput.vue';
import ContextInput from '../editor/config/ContextInput.vue';

const { campaign } = defineProps<{
  campaign: GraphRun;
}>();

const modal = useModal();
const workspaceStore = useWorkspaceStore();
const spellStore = useSpellsStore();
const agentStore = useAgentsStore();

const { agentGraph, hasAccountsLinked } = storeToRefs(agentStore);
const { workspaceId } = storeToRefs(workspaceStore);

const activeStep = ref(0);
const modalBody = ref(null);

const { arrivedState } = useScroll(modalBody);

const steps = computed(() => [
  {
    label: campaign?.id ? 'Campaign settings' : 'Create a campaign',
  },
  {
    label: 'Company Prospecting Goal',
  },
  {
    label: 'Contact Prospecting Goal',
  },
  {
    label: 'Configure Your Tools',
  },
  {
    label: 'Email Templates',
  },
  {
    label: campaign?.id
      ? 'Launch a new campaign with these settings?'
      : 'Congrats on launching the campaign!',
  },
]);

const { data } = useApi(`/api/workspaces/${workspaceId.value}/emails`);

const emailTemplates = computed(() => [
  ...(data.value?.custom ?? []),
  ...(data.value?.official ?? []),
]);

const chosenTemplateId = ref<string | undefined>(undefined);
const chosenTemplate = computed(() => {
  return emailTemplates.value?.find((t) => t.id === chosenTemplateId.value);
});

const state = reactiveComputed(() => {
  return {
    companyProspectingGoal: campaign?.inputs?.companyProspectingGoal ?? '',
    leadGoal: campaign?.inputs?.leadGoal ?? null,
    campaignName: campaign?.name ?? '',
    numberofleads: campaign?.inputs?.numberofleads ?? 30,
    technologies: campaign?.inputs?.technologies ?? null,
    hiring_for: campaign?.inputs?.hiring_for ?? null,
    reach: 'amount',
    foundedYearMin: campaign?.inputs?.foundedYearMin ?? null,
    foundedYearMax: campaign?.inputs?.foundedYearMax ?? null,
    funding_stages: campaign?.inputs?.funding_stages ?? null,
    number_of_employees: campaign?.inputs?.number_of_employees ?? null,
    location: campaign?.inputs?.location ?? null,
    industry: campaign?.inputs?.industry ?? null,
    person_locations: campaign?.inputs?.person_locations ?? null,
    person_seniorities: campaign?.inputs?.person_seniorities ?? null,
    person_titles: campaign?.inputs?.person_titles ?? null,
  };
});

const templateState = reactiveComputed(() => {
  return {
    templatesubject:
      campaign?.inputs?.templatesubject ?? chosenTemplate.value?.subject,
    templatebody: campaign?.inputs?.templatebody ?? chosenTemplate.value?.body,
    templateDescription:
      campaign?.inputs?.templateDescription ??
      chosenTemplate.value?.instructions,
  };
});

const reachOptions = computed(() => [
  {
    type: 'time',
    label: 'Time based',
    icon: 'i-ph-clock',
    description: 'Define when to start and end the campaign',
    isActive: state.reach === 'time',
    disabled: true,
  },
  {
    type: 'amount',
    label: 'Amount based',
    icon: 'i-ph-users-three',
    description: 'Define how many leads to outreach',
    isActive: state.reach === 'amount',
  },
]);

const onSubmit = async () => {
  await agentStore.linkSteps();

  const globalState = { ...agentStore.configState };
  const { campaignName, reach, ...campaignInputs } = { ...state };

  modal.close();

  spellStore.startSpell(
    { ...templateState, ...campaignInputs, ...globalState },
    'agent',
    campaignName,
  );

  await navigateTo({ name: 'agent.campaigns' });

  setTimeout(async () => {
    await agentStore.loadCampaigns();
  }, 1000);
};
</script>
<template>
  <UModal :ui="{ width: 'sm:max-w-3xl' }">
    <UCard
      :ui="{
        base: 'w-full',
        divide: 'divide-y-0',
        body: { padding: '!py-0', base: 'relative' },
        header: { base: 'flex flex-col gap-5' },
        footer: { base: 'flex w-full' },
      }"
    >
      <template #header>
        <UProgress :value="activeStep + 1" :max="6" size="sm" class="w-1/2" />

        <div class="w-full flex justify-between">
          <p class="text-2xl font-bold">{{ steps[activeStep].label }}</p>
          <UButton
            :padded="false"
            color="gray"
            variant="link"
            icon="i-ph-x"
            @click="modal.close"
          />
        </div>
      </template>
      <div
        ref="modalBody"
        class="w-full h-full overflow-y-scroll flex flex-col gap-6 max-h-[60vh] justify-start py-4"
      >
        <!-- OVERFLOW SHADOW -->
        <div
          class="absolute inset-x-0 top-0 h-2 bg-gradient-to-b from-black transition-opacity z-50"
          :class="arrivedState.top ? 'opacity-0' : 'opacity-20'"
        />

        <!-- STEP 1 -->
        <div v-if="activeStep === 0" class="contents">
          <UFormGroup label="Campaign name" class="w-full" size="xl">
            <UInput
              v-model="state.campaignName"
              placeholder="Name your campaign"
            />
          </UFormGroup>
          <UFormGroup
            label="Campaign reach"
            class="w-full"
            :ui="{ container: 'flex gap-2' }"
          >
            <UTooltip
              v-for="option in reachOptions"
              :key="option.type"
              :prevent="!option.disabled"
              text="Time based campaigns are coming soon"
              class="w-1/2 h-32"
            >
              <UButton
                :color="option.isActive ? 'primary' : 'gray'"
                :variant="option.isActive ? 'soft' : 'outline'"
                :disabled="option.disabled"
                size="xl"
                class="w-full"
                :ui="{
                  rounded: 'rounded-xl',
                  variant: {
                    soft: 'border border-primary-300',
                  },
                  padding: {
                    xl: 'py-5',
                  },
                }"
                @click="() => (state.reach = option.type as string)"
              >
                <div class="flex flex-col items-start space-y-2">
                  <div class="flex flex-row items-center space-x-2">
                    <UIcon
                      :name="option.icon"
                      class="text-2xl"
                      :class="
                        option.isActive ? 'text-primary-500' : 'text-black'
                      "
                    />
                    <p
                      class="title"
                      :class="
                        option.isActive ? 'text-primary-500' : 'text-black'
                      "
                    >
                      {{ option.label }}
                    </p>
                  </div>
                  <p
                    class="body text-left"
                    :class="option.isActive ? 'text-primary-500' : 'dimmed'"
                  >
                    {{ option.description }}
                  </p>
                </div>
                <input
                  type="radio"
                  :checked="option.isActive"
                  class="h-7 w-7 accent-primary-500 ml-auto"
                />
              </UButton>
            </UTooltip>
          </UFormGroup>
          <AppInput
            v-model="state.numberofleads"
            :option="agentGraph?.inputs?.numberofleads"
          />

          <!-- TODO: Add emails per day amount -->
          <!-- <UFormGroup
          v-if="state.reach === 'amount'"
          label="How many emails to send per day"
          class="w-full"
          :hint="state.emailAmount.toString()"
        >
          <URange v-model="state.emailAmount" :min="0" :max="1000" />
        </UFormGroup> -->

          <!-- TODO: Add back date range -->
          <!-- <UFormGroup v-else label="Start date" class="w-full">
          <AppCalendar
            v-model="state.range"
            type="daterange"
            class="shrink-0"
          />
        </UFormGroup> -->
        </div>

        <!-- STEP 2 -->
        <div v-else-if="activeStep === 1" class="contents">
          <AppInput
            v-model="state.companyProspectingGoal"
            :option="agentGraph?.inputs?.companyProspectingGoal"
          />
          <p class="uppercase dimmed font-bold mr-auto">Filters:</p>
          <span class="w-full grid grid-cols-2 gap-4">
            <ContextInput
              v-model="state.technologies"
              :option="agentGraph?.inputs?.technologies"
            />
            <ContextInput
              v-model="state.hiring_for"
              :option="agentGraph?.inputs?.hiring_for"
            />
            <AppInput
              v-model="state.number_of_employees"
              :option="agentGraph?.inputs?.number_of_employees"
            />
            <ContextInput
              v-model="state.location"
              :option="agentGraph?.inputs?.location"
              class="w-full"
            />
            <ContextInput
              v-model="state.funding_stages"
              :option="agentGraph?.inputs?.funding_stages"
              class="w-full"
            />
            <ContextInput
              v-model="state.industry"
              :option="agentGraph?.inputs?.industry"
              class="w-full"
            />
          </span>
        </div>

        <div v-else-if="activeStep === 2" class="contents">
          <AppInput
            v-model="state.leadGoal"
            :option="agentGraph?.inputs?.leadGoal"
          />
          <p class="uppercase dimmed font-bold mr-auto">Filters:</p>
          <span class="w-full grid grid-cols-2 gap-4">
            <ContextInput
              v-model="state.person_locations"
              :option="agentGraph?.inputs?.person_locations"
            />
            <ContextInput
              v-model="state.person_seniorities"
              :option="agentGraph?.inputs?.person_seniorities"
            />
            <ContextInput
              v-model="state.person_titles"
              :option="agentGraph?.inputs?.person_titles"
            />
          </span>
        </div>

        <Tools v-else-if="activeStep === 3" class="w-full" />

        <div v-else-if="activeStep === 4" class="contents">
          <UFormGroup
            label="Select an email template to pre-fill the items below"
            description="Select one of Respell's pre-made templates or a custom one you've made before"
            class="w-full"
            size="xl"
          >
            <USelectMenu
              v-model="chosenTemplateId"
              :options="emailTemplates"
              value-attribute="id"
              option-attribute="name"
              class="w-full"
              size="xl"
              placeholder="Select template"
            />
          </UFormGroup>
          <UDivider label="OR" />
          <AppInput
            :key="chosenTemplateId"
            v-model="templateState.templatesubject"
            class="w-full"
            :option="agentGraph?.inputs?.templatesubject"
          />
          <AppInput
            :key="chosenTemplateId"
            v-model="templateState.templatebody"
            class="w-full"
            :option="agentGraph?.inputs?.templatebody"
          />
          <AppInput
            :key="chosenTemplateId"
            v-model="templateState.templateDescription"
            class="w-full"
            :option="agentGraph?.inputs?.templateDescription"
          />
        </div>

        <div v-else-if="activeStep === 5" class="contents">
          <div
            class="flex flex-col w-full items-start gap-2 bg-primary-100 p-4 rounded-xl"
          >
            <UBadge
              size="sm"
              :ui="{
                rounded: 'rounded-full',
                base: 'gap-1',
                variant: {
                  solid: 'bg-primary-300',
                },
              }"
            >
              <UIcon name="i-ph-sparkle" />
              <p>SDR Agent</p>
            </UBadge>
            <p class="body font-bold text-primary-500">
              We will add a tag in your CRM
            </p>
            <p class="body-sm text-primary-500">
              Respell will automatically add a tag “SDR Agent” to new contacts
              created in your CRM of choice.
            </p>
          </div>
        </div>

        <!-- OVERFLOW SHADOW -->
        <div
          v-if="![0, 3, 5].includes(activeStep)"
          class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-t from-black transition-opacity"
          :class="arrivedState.bottom ? 'opacity-0' : 'opacity-20'"
        />
      </div>

      <template #footer>
        <!-- BACK BUTTON -->
        <UButton
          v-if="activeStep > 0"
          label="Back"
          variant="ghost"
          color="gray"
          size="lg"
          @click="activeStep -= 1"
        />
        <!-- NEXT / ACTION BUTTON -->
        <UButton
          v-if="activeStep === 5"
          label="Start the Campaign"
          variant="solid"
          color="primary"
          trailing-icon="i-ph-rocket-launch"
          size="lg"
          class="ml-auto"
          @click="onSubmit"
        />
        <UTooltip
          v-else
          :prevent="activeStep !== 3 || hasAccountsLinked"
          text="Connect your tools before starting the campaign"
          class="ml-auto"
        >
          <UButton
            label="Next"
            variant="solid"
            color="primary"
            :disabled="activeStep === 3 && !hasAccountsLinked"
            size="lg"
            @click="activeStep += 1"
          />
        </UTooltip>
      </template>
    </UCard>
  </UModal>
</template>
